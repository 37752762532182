<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div class="pie-chart">
    <svg class="pie-chart__donut"
         :viewBox="`0 0 ${this.viewBoxHeight} ${this.viewBoxHeight}`"
         ref="main_area"
         @click="this.changeTotalModeEvent"
         :style="{
          'min-height' : this.diagramHeight + 'px',
          'min-width': this.diagramWidth + 'px',
          'max-height' : this.diagramHeight + 'px',
          'max-width': this.diagramWidth + 'px',
         }">

      {{ this.legendData ? null : null }}

      <template v-if="this.isAdvancedView">
        <foreignObject v-for="curData, dataId in this.pathLegendTexts" :key="dataId"
        :style="{
          background: curData.color,
          'border-radius': `${
              curData.alignLeft && curData.alignUp ? 0 : 3
            }px ${
              !curData.alignLeft && curData.alignUp ? 0 : 3
            }px ${
              !curData.alignLeft && !curData.alignUp ? 0 : 3
            }px ${
              curData.alignLeft && !curData.alignUp ? 0 : 3
            }px`,
          'transform-origin': `${curData.basePos.x}px ${curData.basePos.y}px`,
          transform: 'rotate(0deg)',
          'text-align': curData.alignLeft ? null : 'end',
          'align-content': curData.alignUp ? null : 'end',
        }"
                        :x="curData.basePos.x - (curData.alignLeft ? 0: curData.w)"
                        :y="curData.basePos.y - (curData.alignUp ? 0: curData.h)"
                        :width="curData.w"
                        :height="curData.h">
          <div :style="{
              'font-size': this.smallFontSize + 'px',
                'max-height': curData.h + 'px',
            }"
              @click="this.selectElement(dataId, true);"
              class="button-front-color legend"
              style="padding: 1px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;"
          >
            {{ curData.view }}
          </div>
        </foreignObject>
      </template>

      <g v-if="!this.isEmpty" :style="`transform: rotate(${90 + this.angle}deg); transform-origin: center;`">
        <!-- <circle :cx="this.chartCenter" :cy="this.chartCenter"
          :r="this.legendBaseRadius" fill="#00FF00A0"/> -->

        <!-- <line v-for="curData, dataId in this.series" :key="dataId"
              :x1="this.chartCenter"
              :y1="this.chartCenter"
              :x2="this.chartCenter + this.rotateVector({x: 1, y: 0}, curData.startAngle + curData.angle / 2).x * this.legendBaseRadius"
              :y2="this.chartCenter + this.rotateVector({x: 1, y: 0}, curData.startAngle + curData.angle / 2).y * this.legendBaseRadius"
              :stroke-width="1"
              stroke="red"
              /> -->

        <circle v-for="curData, dataId in this.series" :key="dataId"
                v-show="curData.angle >= 0.9"
                :cx="this.chartCenter" :cy="this.chartCenter"
                :r="this.chartRadius - this.segmentWidth / 2"
                fill="transparent"
                :style="{stroke: this.seriesColor(curData.startAngle)}"
                :stroke-width="this.segmentWidth"
                :stroke-dasharray="`${this.getAngleLength(curData.angle)}, ${this.getAngleLength(360 - curData.angle)}`"
                :stroke-dashoffset="-this.getAngleLength(curData.startAngle)"
                />

        <template v-if="this.isAdvancedView">
          <template v-for="curData, dataId in this.pathLegendTexts" :key="dataId">
            <path
                :id="curData.idx + '_1'"
                :d="curData.valuePath"
                stroke="transparent" stroke-width="1" fill="transparent"/>
            <path
                :id="curData.idx + '_2'"
                :d="curData.multPath"
                stroke="transparent" stroke-width="1" fill="transparent"/>

            <text dominant-baseline="middle"
                  class="button-front-color"
                  :style="{'font-size': this.microFontSize + 'px'}"
            >
              <textPath startOffset="50%" text-anchor="middle" :href="'#' + curData.idx + '_1'">
                {{ curData.value }}
              </textPath>
            </text>

            <text dominant-baseline="middle"
                  class="button-front-color"
                  :style="{'font-size': this.microFontSize + 'px'}"
            >
              <textPath startOffset="50%" text-anchor="middle" :href="'#' + curData.idx + '_2'">
                {{ curData.multText }}
              </textPath>
            </text>
          </template>
        </template>

          <Transition name="transparent__fade">
            <circle v-if="!this.isTotalMode"
                    :cx="this.chartCenter" :cy="this.chartCenter"
                    :r="this.chartRadius - this.segmentWidth / 2"
                    fill="transparent"
                    class="pie-chart__stroke-background-color"
                    :stroke-width="this.segmentWidth"
                    :stroke-dasharray="`${this.getAngleLength(360)}, ${this.getAngleLength(0)}`"
                    :stroke-dashoffset="-this.getAngleLength(0)"
                    />
          </Transition>
          <Transition name="transparent__fade">
            <circle v-if="!this.isTotalMode"
                    v-show="this.normSelAngle.a"
                    :cx="this.chartCenter" :cy="this.chartCenter"
                    :r="this.chartRadius - this.segmentWidth / 2"
                    fill="transparent"
                    :style="{stroke: this.seriesColor(this.series[this.curSelectedSeries]?.startAngle)}"
                    :stroke-width="this.segmentWidth"
                    :stroke-dasharray="`${this.getAngleLength(this.normSelAngle.a)}, ${this.getAngleLength(360 - this.normSelAngle.a)}`"
                    :stroke-dashoffset="-this.getAngleLength(this.normSelAngle.s)"
                    />
          </Transition>
      </g>

      <circle class="pie-chart__donut__piece"
          ref="last_chart_segment"
          :cx="this.chartCenter" :cy="this.chartCenter"
          :r="this.chartRadius" fill="transparent"/>

      <circle :cx="this.chartCenter" :cy="this.chartCenter"
          :r="this.chartRadius - this.segmentWidth" fill="transparent"/>

      <polygon class="pie-chart__donut__arrow"
          :transform="`translate(${this.chartCenter}, ${this.chartCenter + this.chartRadius - this.segmentWidth - (this.isRotate ? this.chartCenter * 0.14 : this.chartCenter * 0.06)})`"
          :points="`${-this.chartCenter * 0.05}, ${-this.chartCenter * 0.09} ${this.chartCenter * 0.05}, ${-this.chartCenter * 0.09} 0,0`" />

      <foreignObject style="pointer-events: none;"
                      :x="this.chartCenter - this.chartRadius"
                      :y="0"
                      :width="this.chartDiameter"
                      :height="this.chartCenter - this.chartRadius * 0.25">
        <div xmlns="http://www.w3.org/1999/xhtml"
             class="pie-chart__donut__selected-item"
             :style="{ 'font-size': this.smallFontSize + 'px'}">
          {{ this.selectedItemName }}
        </div>
      </foreignObject>

      <text class="pie-chart__donut__total"
          :style="{ 'font-size': this.stdFontSize + 'px'}"
          dominant-baseline="middle"
          :x="this.chartCenter"
          :y="this.chartCenter - this.chartRadius * 0.07">
        {{ this.selectedTotal }}
      </text>

      <text class="pie-chart__donut__meas" v-if="this.isTotalMode"
          :style="{ 'font-size': this.smallFontSize + 'px'}"
          dominant-baseline="middle"
          :x="this.chartCenter" :y="this.chartCenter + this.chartRadius * 0.085">
        {{ this.unitName }}
      </text>

      <text class="pie-chart__donut__meas" v-else
          :style="{ 'font-size': this.smallFontSize + 'px'}"
          dominant-baseline="middle"
          :x="this.chartCenter" :y="this.chartCenter + this.chartRadius * 0.085">
        {{ this.selectedItemPercent }} %
      </text>

      <text
          class="pie-chart__donut__meas"
          :style="{ 'font-size': this.smallFontSize + 'px'}"
          dominant-baseline="middle"
          :x="this.chartCenter" :y="this.chartCenter + this.chartRadius * 0.25">
        {{ this.pointName }}
      </text>
    </svg>

    <ChartLegend  v-show="false && !this.isEmpty"
                  @dataSelect="(el) => this.selectElement(el, true)"
                  :drawData="this.legendData"
                  :selectedData="this.curSelectedSeries"
                  :legendHeight="this.legendHeight"
                  :legendWidth="this.legendWidth"
                  @dataHover="(dataIdx) => this.hoverElem = dataIdx"
                  />
  </div>
</template>


<script type="text/javascript">
import ChartLegend from './ChartLegend.vue';

import gestures from '@/assets/gestures.js';
import varAnimator from '@/assets/varAnimator.js';

function hslToHex(h, s, l) {
  l /= 100;
  const a = s * Math.min(l, 1 - l) / 100;
  const f = (n) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color).toString(16).padStart(2, '0'); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}

function getDecartVectorsAngle(vA, vB) {
  const lenA = Math.sqrt((vA.x * vA.x) + (vA.y * vA.y));
  const lenB = Math.sqrt((vB.x * vB.x) + (vB.y * vB.y));

  let direction = Math.sign(vA.x * vB.y - vA.y * vB.x);
  direction = direction == 0 ? 1 : direction;

  const multiply = (vA.x * vB.x) + (vA.y * vB.y);
  const ans = direction * (Math.acos(multiply / (lenA * lenB)) * 180) / Math.PI;
  return isNaN(ans) ? 0 : ans;
}

export default {
  components: {
    ChartLegend,
  },
  computed: {
    paddingSize() {
      return (this.viewBoxHeight - this.chartDiameter) / 2;
    },
    pathLegendTexts() {
      // console.log(this.multData);

      const baseVector = {x: 1, y: 0};
      return this.series.map((curData, idx) => {
        const transformedAngle = parseInt(((this.angle + 90 + curData.startAngle + curData.angle / 2) % 360) / 90);
        const transformedHalfAngle = parseInt(((this.angle + 45 + curData.startAngle + curData.angle / 2) % 360) / 90);

        // if (idx == 0) {
        //   console.log('curData.view', curData.view, transformedAngle);
        // }

        const p = this.rotateVector(baseVector, this.angle + 90 + curData.startAngle + curData.angle / 2);
        p.x = this.chartCenter + p.x * this.legendBaseRadius;
        p.y = this.chartCenter + p.y *this.legendBaseRadius;

        return {
          canDraw: curData.angle > 25,
          idx: '_' + this.getUniqueNumber(),
          basePos: p,
          alignUp: [0, 1].includes(transformedAngle),
          alignLeft: [0, 3].includes(transformedAngle),
          w: (
            (p.x - this.chartDiameter) < 0 ?
            p.x :
            (
              (p.x + this.chartDiameter) > this.viewBoxHeight ?
              this.viewBoxHeight - p.x :
              this.chartDiameter
            )
          ),
          h: this.smallFontSize * 1.5,
          valuePath: this.calcAnglePath(
              curData.startAngle,
              curData.startAngle + curData.angle,
              this.chartRadius - this.segmentWidth / 3,
              transformedHalfAngle == 0,
          ),
          multPath: this.calcAnglePath(
              curData.startAngle,
              curData.startAngle + curData.angle,
              this.chartRadius - this.segmentWidth * 2 / 3,
              transformedHalfAngle == 0,
          ),
          multText: this.multData.symbol,
          color: this.seriesColor(curData.startAngle),
          view: curData.view,
          value: parseFloat(curData.value / this.multData.multiplier).toLocaleString(
              'ru',
              {'minimumFractionDigits': 2, 'maximumFractionDigits': 2},
          ),
        };
      }).filter((elem) => elem.canDraw);
    },
    legendBaseRadius() {
      return this.chartRadius * 1.03;
    },
    microFontSize() {
      return this.chartDiameter * 0.06;
    },
    smallFontSize() {
      return this.chartDiameter * 0.08;
    },
    stdFontSize() {
      return this.chartDiameter * 0.13;
    },
    isTotalMode() {
      return this.isRotate ? true : (this.hoverElem != null ? false : this.totalMode);
    },
    curSelectedSeries() {
      return (!this.isRotate && this.hoverElem != null) ? this.hoverElem : this.selectedSeries;
    },
    normSelAngle() {
      const curElem = this.series[this.curSelectedSeries] ? this.series[this.curSelectedSeries]: {startAngle: 0, angle: 0};

      const maxAngle = (!this.isRotate && this.hoverElem != null) ? 360 : 170;
      const angle = Math.min(maxAngle, Math.max(curElem.angle, 10));
      const startPoint = curElem.startAngle - (angle - curElem.angle) * 0.5;
      return {
        a: angle,
        s: startPoint,
      };
    },
    selectedItemPercent() {
      return (this.series[this.curSelectedSeries]?.percent * 100).toFixed(2);
    },
    selectedTotal() {
      return (
        this.isTotalMode ?
        this.totalValue :
        parseFloat(this.series[this.curSelectedSeries]?.value).toLocaleString(
            'ru',
            {'minimumFractionDigits': 2, 'maximumFractionDigits': 2},
        )
      );
    },
    selectedItemName() {
      return this.isTotalMode ? 'Всего' : this.series[this.curSelectedSeries]?.view;
    },
    inactiveblockColor() {
      return '#FFFFFFE0';
    },
    legendData() {
      this.series.forEach((elem, idx) => {
        elem.color = this.seriesColor(elem.startAngle);
        elem.view = elem.name;
      });
      return this.series;
    },
    chartCenter() {
      return this.viewBoxHeight / 2;
    },
    // chartDiameter() {
    //   return this.isAdvancedView ? this.viewBoxHeight * 0.60 : this.viewBoxHeight * 0.9;
    // },
    segmentWidth() {
      return this.chartDiameter * 0.17;
    },
    chartRadius() {
      return this.chartDiameter / 2;
    },
    chartInnerRadius() {
      return this.chartRadius - this.segmentWidth;
    },
    isAdvancedView() {
      return this.$store.state.viewMode == 'advanced';
    },
  },
  props: [
    'diagramWidth',
    'diagramHeight',
    'viewBoxWidth',
    'viewBoxHeight',
    'legendHeight',
    'legendWidth',

    'selectedSeries',

    // 'segmentWidth',
    'angle',

    'totalValue',
    'unitName',
    'pointName',
    'series',

    'isEmpty',

    'totalMode',
    'multData',
    'elemIdx',
  ],
  emits: [
    'elementSelected',
    'changeTotalMode',
    'chartPointerMove',
  ],
  data: () => ({
    isRotate: false,
    hoverElem: null,
    chartDiameter: 1,
  }),
  watch: {
    viewBoxHeight() {
      this.recalcDiameter();
    },
    isAdvancedView() {
      this.recalcDiameter();
    },
  },
  methods: {
    recalcDiameter() {
      const newD = this.isAdvancedView ? this.viewBoxHeight * 0.60 : this.viewBoxHeight * 0.9;
      // this.chartDiameter = newD;

      varAnimator(this.chartDiameter, newD, (aVal) => this.chartDiameter = aVal);
    },
    getUniqueNumber() {
      const ans = window.pathCounter == null ? 0 : window.pathCounter + 1;
      window.pathCounter = ans;
      return ans;
    },
    calcAnglePath(startA, endA, radius, flip=false) {
      if (flip) {
        const tmp = startA;
        startA = endA;
        endA = tmp;

        if (startA - endA > 180) {
          return this.calcAnglePath(endA + 179, startA, radius, flip) + ' ' + this.calcAnglePath(endA, endA + 179, radius, flip);
        }
      }
      if (endA - startA > 180) {
        return this.calcAnglePath(startA, startA + 179, radius) + ' ' + this.calcAnglePath(startA + 179, endA, radius);
      }

      const baseVector = {x: 1, y: 0};

      const startPos = this.rotateVector(baseVector, startA);
      const endPos = this.rotateVector(baseVector, endA);

      return `M ${
        this.chartCenter + startPos.x * radius
      } ${
        this.chartCenter + startPos.y * radius
      } A ${radius} ${radius} 0 0 ${flip ? 0 : 1} ${
        this.chartCenter + endPos.x * radius
      } ${
        this.chartCenter + endPos.y * radius
      }`;
    },
    rotateVector(v, angle) {
      angle = angle * (Math.PI / 180);
      return {
        x: v.x * Math.cos(angle) - v.y * Math.sin(angle),
        y: v.x * Math.sin(angle) + v.y * Math.cos(angle),
      };
    },
    // sin(val) {
    //   return Math.sin(val);
    // },
    // cos(val) {
    //   return Math.cos(val);
    // },
    changeTotalModeEvent(event) {
      if (
        event.target.classList.contains('pie-chart__donut__piece') ||
        event.target.classList.contains('legend')
      ) return;
      this.$emit('changeTotalMode');
    },
    seriesIdByAngle(angle) {
      return this.series.reduce(
          (findId, item, idx) => (item.startAngle <= angle) && (angle <= item.startAngle + item.angle) ? idx : findId,
          -1,
      );
    },
    selectElement(seriesId, selectFromLegend=false) {
      this.$emit('elementSelected', seriesId, selectFromLegend);
    },
    pointerPositionToLocal(px, py) {
      const mainRect = this.$refs.main_area.getBoundingClientRect();
      const radius = this.diagramWidth / 2;
      return {x: px - (mainRect.left + radius), y: py - (mainRect.top + radius)};
    },
    dragStart() {
      this.isRotate = true;
      this.rotateStartAngle = this.angle;
    },
    dragEnd(endData) {
      this.isRotate = false;
      if (this.rotateStartAngle == null) return;
      const startAngle = this.rotateStartAngle;
      this.rotateStartAngle = undefined;

      if (Math.abs(startAngle - this.angle) > 1) {
        const selectAngle = 360 - this.angle;
        this.selectElement(this.seriesIdByAngle(selectAngle));
        return;
      }

      let pointerClickAngle = getDecartVectorsAngle(
          {x: 0, y: -1},
          this.pointerPositionToLocal(endData.x, endData.y),
      ) + 180 - this.angle;
      pointerClickAngle = pointerClickAngle < 0 ? pointerClickAngle + 360 : pointerClickAngle;

      this.selectElement(this.seriesIdByAngle(pointerClickAngle));
    },
    hoverController(hoverMoveData) {
      let pointerClickAngle = getDecartVectorsAngle(
          {x: 0, y: -1},
          this.pointerPositionToLocal(hoverMoveData.x, hoverMoveData.y),
      ) + 180 - this.angle;
      pointerClickAngle = pointerClickAngle < 0 ? pointerClickAngle + 360 : pointerClickAngle;

      this.hoverElem = this.seriesIdByAngle(pointerClickAngle);
    },
    cancelHover(hoverMoveData) {
      this.hoverElem = null;
    },
    dragController(dragData) {
      const fp = this.pointerPositionToLocal(dragData.pX, dragData.pY);
      const sp = this.pointerPositionToLocal(dragData.pX + dragData.x, dragData.pY + dragData.y);
      const deltaAngle = getDecartVectorsAngle(fp, sp);

      this.$emit('chartPointerMove', deltaAngle);
    },
    getAngleLength(angle) {
      return ((angle / 360) * (Math.PI * (this.chartDiameter - this.segmentWidth))).toFixed(2);
    },
    seriesColor(angleStart) {
      return hslToHex(160 + (1 - angleStart/360) * 160, 60, 50)+'f0';
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.$refs.last_chart_segment == null) return;

      this.gestController = gestures(this.$refs.last_chart_segment);
      this.gestController.registerDrag(this.dragController, this.dragStart, this.dragEnd);
      this.gestController.registerHover(null, this.hoverController, this.cancelHover);
    }, 1);
    this.recalcDiameter();
  },
  beforeUnmount() {
    if (this.gestController != null) this.gestController.destruct();
  },
};
</script>

<style lang="less">
.transparent {
  &__fade-enter-active,
  &__fade-leave-active {
    transition: opacity 200ms;
  }

  &__fade-enter-from,
  &__fade-leave-to {
    opacity: 0;
  }
}

.pie-chart {
  position: relative;
  .flex(row, center, center);
  flex-wrap: wrap;

  &__stroke-background-color {
    stroke: @content-background-color;
  }

  &__donut {
    // margin-right: 13px;
    // margin-bottom: 13px;

    &__arrow {
      fill: @base-text-color;
      transition: all 200ms linear;
    }

    &__total {
      text-anchor: middle;
      font-weight: bold;
      font-size: 11px;
      fill: @base-text-color;
    }

    &__meas {
      text-anchor: middle;
      font-size: 8px;
      fill: @base-text-color;
    }

    &__selected-item {
      align-content: end;
      place-content: end;
      text-align: center;
      height: 100%;
      width: 100%;
      font-size: 8px;
      color: @no-accent-text-color;
    }

    &__piece {
      cursor: pointer;
    }
  }
}
</style>
