<template>
  <div :class="this.vertical ? 'v-topPanelFrame' : 'topPanelFrame'">
    <div :class="this.vertical ? 'flex-column' : 'flex-row'">
      <IconComponent @click="this.setTheme(this.selectedTheme + 1)"
                     :url="this.themes[this.selectedTheme].icon"
                     class="icon-over-transparency-color"
                     size="28"/>

      <IconComponent @click="this.setViewMode(this.selectedViewMode + 1)"
                     :url="this.viewModes[this.selectedViewMode].icon"
                     class="icon-over-transparency-color"
                     size="28"/>
    </div>
    <ProfileFrame v-if="!this.$store.state.autostart_mode"/>
  </div>
</template>

<script>
import ProfileFrame from './ProfileFrame.vue';
import IconComponent from '@/units/RichIcon.vue';


export default {
  name: 'TopPanel',
  components: {
    IconComponent,
    ProfileFrame,
  },
  props: ['vertical'],
  data: () => ({
    themes: [
      {
        value: 'old',
        icon: '/icons/system/half_circle.svg',
      },
      {
        value: 'dark',
        icon: '/icons/system/moon.svg',
      },
      {
        value: 'light',
        icon: '/icons/system/sun.svg',
      },
    ],
    viewModes: [
      {
        value: 'light',
        icon: '/icons/system/crossed_eye.svg',
      },
      {
        value: 'advanced',
        icon: '/icons/system/eye.svg',
      },
    ],
    selectedTheme: 0,
    selectedViewMode: 0,
  }),
  mounted() {
    this.themes.forEach((elem, idx) => {
      if (elem.value == this.$store.state.theme_type) this.selectedTheme = idx;
    });
    this.viewModes.forEach((elem, idx) => {
      if (elem.value == this.$store.state.viewMode) this.selectedViewMode = idx;
    });
  },
  methods: {
    setTheme(idx) {
      if (idx >= this.themes.length) idx = 0;
      this.selectedTheme = idx;
      this.$store.commit('setTheme', this.themes[this.selectedTheme].value);
    },
    setViewMode(idx) {
      if (idx >= this.viewModes.length) idx = 0;
      this.selectedViewMode = idx;
      this.$store.commit('viewModeMut', this.viewModes[this.selectedViewMode].value);
    },
  },
};


</script>

<style  lang="less">
.topPanelFrame{
  width: 100%;
  .flex(row, space-between, center);
  padding: 0 @base-gap 0;
}

.v-topPanelFrame{
  .flex(column, space-between, center);
  padding: @base-gap 0;
}
</style>
