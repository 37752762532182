<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div ref="root" class="input_box" @click="this.setFocused(true)" :class="{'input_box_focused': this.isFocused, 'input_box_fixed_height': !this.flexHeight}">
    <div class="input_box_content" :class="{'input_box_hide_data': this.hideData && !this.isFocused}">
      <h2 class="input_box_title">
        <span>{{ this.title }} </span>
        <span class="required" v-if="this.is_required">* </span>
        <span class="error-text" v-if="this.error != null">{{ this.error }}</span>
      </h2>
      <div class="input_box_field">
        <slot></slot>
      </div>
    </div>
    <!-- <IconComponent v-if="this.iconUrl != null" :url="this.iconUrl" class="input_box_icon" size="24"/> -->
    <div class="disable-fader" v-if="this.disabled"></div>
  </div>
</template>

<script type="text/javascript">
// import IconComponent from '@/units/RichIcon.vue';

export default {
  components: {
    // IconComponent,
  },
  emits: ['focusChange'],
  data: () => ({
    isFocused: false,
  }),
  watch: {
    setFocus() {
      this.setFocused(this.setFocus);
    },
  },
  props: {
    is_required: {
      default: false,
      required: false,
    },
    title: {
      default: '',
      required: false,
    },
    error: {
      default: null,
      required: false,
    },
    hideData: {
      default: false,
      required: false,
    },
    iconUrl: {
      default: null,
      required: false,
    },
    setFocus: {
      default: false,
      required: false,
    },
    flexHeight: {
      default: false,
      required: false,
    },
    disabled: {
      default: false,
      required: false,
    },
  },
  created() {
    document.addEventListener('click', this.checkFocusOut);
    // window.escapeableElements.push(this.escKey);
  },
  mounted() {
    this.setFocused(this.setFocus);
  },
  computed: {

  },
  unmounted() {
    document.removeEventListener('click', this.checkFocusOut);

    // const index = window.escapeableElements.indexOf(this.escKey);
    // if (index > -1) {
    //   window.escapeableElements.splice(index, 1);
    // }
  },
  methods: {
    escKey() {
      this.setFocused(false);
    },
    checkFocusOut(event) {
      if (!this.isFocused) return;

      // закрытие режима редактирования
      const rootElem = this.$refs.root;

      if (['HTML', 'BODY'].includes(event.target.nodeName) ||
        (rootElem != null &&
        !rootElem.contains(event.target) &&
        document.body.contains(event.target))) {
        this.setFocused(false);
      }
    },
    setFocused(val) {
      if (this.disabled) val = false;

      this.isFocused = val;
      setTimeout(() => this.$emit('focusChange', this.isFocused), 1);
    },
  },
};
</script>

<style lang="less">
@import '/src/assets/less/constants.less';

.input_box {
  // .flex(row, flex-start, center);
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  // background: red;
  border: 1px solid @no-accent-text-color;
  border-radius: 6px;
  min-height: 56px;
  // padding: @base-gap;
  width: 100%;
  cursor: text;

  .disable-fader {
    border-radius: 6px;
    position: absolute;
    width: 100%;
    height: 100%;
    background: @fade-content-background-color;
    cursor: not-allowed;
  }

  &_fixed_height {
    height: 56px;
    max-height: 56px;
  }

  &_focused {
    border-color: @base-accent;
  }

  &_content {
    // .flex(column, flex-start, stretch);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    // user-select: none;
    flex-grow: 1;
    margin: @base-gap;

    .input_box_title {
      overflow: auto;
      max-height: 12px;
      font-size: 10px;
        line-height: 12px;
      & * {
        color: @no-accent-text-color;
        // font-weight: normal;
        font-size: 10px;
        line-height: 12px;
      }
      .required {
        color: @base-error;
      }
      .error-text {
        color: @base-error;
      }
    }
  }

  .input_box_hide_data {
    .input_box_title {
      max-height: 17px;
      & * {
        // font-weight: normal;
        font-size: 14px;
        line-height: 17px;
      }
    }

    .input_box_field {
      display: none;
    }
  }

  &_icon {
    cursor: pointer;
  }
}
</style>
