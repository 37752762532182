import {createStore} from 'vuex';
import {tokenRegistrator} from '@/ProjectInjections/MobileAppInjection';
import pagesInject from '@/ProjectInjections/localPagesInject';

const curUrl = new URL(window.location.href);
let serverPort = '';
let isExperementsProd = false;


let devSettings = require.context('@/assets', true, /dev_settings\.js$/);
devSettings = devSettings.keys()[0] == null ? null : devSettings(devSettings.keys()[0]);
if (devSettings != null && devSettings.SERVER_PORT != null) serverPort = devSettings.SERVER_PORT;
if (devSettings != null && devSettings.EXPEREMENTS_PROD != null) isExperementsProd = devSettings.EXPEREMENTS_PROD;


const AUTOSTART_MODE = curUrl.searchParams.get('autostart') != null;


export const ROOT_URL = curUrl.protocol + '//' + curUrl.hostname + serverPort;
export const IS_EXPEREMENTS_PROD = isExperementsProd;
export const MAX_ERROR_TEXT_LEN = 150;

function getFastPanelStruct(sitePages) {
  return sitePages.filter((elem) => elem.in_fast_panel);
}

const store = createStore({
  state: {
    critical_error: null,
    user_obj: null,
    fast_panel_struct: null,
    raw_pages_struct: null,
    web_site_struct: null,
    page_templates_struct: null,
    current_page_info: null,
    autostart_mode: AUTOSTART_MODE,
    theme_type: 'dark',
    viewMode: 'light',
    screenMode: {
      screenMode: 'twoScreen',
      isLandscape: false,
    },
  },
  getters: {
    isLoggedIn: (state, getters) => {
      return state.user_obj != null && !state.user_obj.is_anonymous;
    },
    webSitePagesRoot: (state, getters) => {
      return state.web_site_struct.filter((elem) => elem.parent_page == null); // .sort(pageSorter);
    },
    pageByUid: (state) => (uid) => {
      let ans = null;
      state.web_site_struct.forEach((elem) => elem.uid == uid ? (ans = elem) : null);
      return ans;
    },
    isUserOwner: (state, getters) => {
      return state.user_obj?.is_owner;
    },
  },
  mutations: {
    viewModeMut(state, viewMode) {
      state.viewMode = viewMode;
      try {
        localStorage.setItem('view_mode', viewMode);
      } catch {}
    },
    screenModeMut(state, screenMode) {
      state.screenMode = screenMode;
    },
    critical_error_mut(state, {type, shortInfo, fullInfo}) {
      state.critical_error = {
        type: type,
        shortInfo: shortInfo,
        fullInfo: fullInfo,
      };
    },
    setTheme(state, newTheme) {
      state.theme_type = newTheme;
      try {
        localStorage.setItem('theme_type', newTheme);
      } catch {}
      document.getElementsByTagName('body')[0].className = newTheme;
    },
    user_obj_mut(state, data) {
      state.user_obj = data;
      if (!data.is_anonymous) tokenRegistrator();
      rebuildSiteStruct();
    },
    web_site_struct_mut(state, data) {
      state.raw_pages_struct = data;
      rebuildSiteStruct();
    },
    page_templates_struct_mut(state, data) {
      state.page_templates_struct = data;
      rebuildSiteStruct();
    },
    current_page_info_mut(state, data) {
      state.current_page_info = data;
    },
  },
  actions: {
  },
  modules: {
  },
});

// function getNormalisedPagesStruct(rawPages) {
//   const ans = {};

//   rawPages.sort(pageSorter);

//   rawPages.forEach((elem) => {
//     ans[elem.uid] = elem;
//     ans[elem.uid].children = [];
//   });

//   rawPages.forEach((elem) => {
//     if (!elem.hasOwnProperty('fullUrl')) {
//       Object.defineProperty(elem, 'fullUrl', {get: function() {
//         if (this.page_type == 'ext_link') {
//           return this.url;
//         }
//         return (
//           this.parent_page != null ?
//           this.parent_page.fullUrl :
//           (this.internal_page ? '/' : (this.is_template ? '/t_page/' : '/ext_page/'))
//         ) + this.url + '/';
//       }});
//     }

//     if (ans[elem.parent_page_uid] == null) {
//       elem.parent_page_uid = null;
//       return;
//     }
//     ans[elem.parent_page_uid].children.push(elem);
//     elem.parent_page = ans[elem.parent_page_uid];
//   });

//   return ans;
// }

function prepareTemplatePages(templatePages) {
  templatePages.forEach((elem, idx) => {
    elem = Object.assign({
      tuid: 't_' + elem.uid,
    }, elem);

    elem.is_template = true;
    elem.is_ghost = true;
    elem.in_fast_panel = false;

    templatePages[idx] = elem;

    // if (elem.uid != null && elem.uid[0] != 't') elem.uid = 't_' + elem.uid;
    // if (
    //   elem.parent_page_uid != null &&
    //   elem.parent_page_uid[0] != 't'
    // ) elem.parent_page_uid = 't_' + elem.parent_page_uid;
  });
}

function getPlainPagesDict(userPages, templatePages, localPages) {
  const ans = {};

  function prefillPages(basedList) {
    basedList.forEach((elem) => {
      ans[elem.uid] = elem;
      ans[elem.uid].children = [];

      if (ans[elem.uid].is_template) {
        ans[elem.uid].user_pages_children = [];
        ans[elem.uid].templates_children = [];
      }

      elem.view = elem.name;

      if (!elem.hasOwnProperty('fullUrl')) {
        Object.defineProperty(elem, 'fullUrl', {get: function() {
          if (this.page_type == 'ext_link') {
            return this.url;
          }
          return (
            this.parent_page != null ?
            this.parent_page.fullUrl :
            (this.internal_page ? '/' : (this.is_template ? '/t_page/' : '/ext_page/'))
          ) + this.url + '/';
        }});
      }
    });
  }

  prefillPages(templatePages);
  prefillPages(userPages);
  prefillPages(localPages);

  return ans;
}

function fillPageRelations(pagesDict) {
  Object.values(pagesDict).forEach((elem) => {
    if (pagesDict[elem.template_page_uid] != null && !elem.is_template && elem.page_type != 'group') {
      elem.template_page = pagesDict[elem.template_page_uid];
      pagesDict[elem.template_page_uid].not_use = true;

      if (elem.use_template_parent) elem.parent_page_uid = elem.template_page.parent_page_uid;
    }

    if (pagesDict[elem.parent_page_uid] == null) {
      elem.orig_parent_page_uid = elem.parent_page_uid;
      elem.parent_page_uid = null;
    } else if (
      pagesDict[elem.parent_page_uid].is_template && !elem.is_template
    ) pagesDict[elem.parent_page_uid].user_pages_children.push(elem);
    else if (pagesDict[elem.parent_page_uid].is_template) pagesDict[elem.parent_page_uid].templates_children.push(elem);
    else pagesDict[elem.parent_page_uid].children.push(elem);

    elem.parent_page = pagesDict[elem.parent_page_uid];
  });

  Object.values(pagesDict).forEach((elem) => {
    if (!elem.is_template || elem.page_type != 'group') return;

    elem.user_pages_children.forEach((child) => elem.children.push(child));
    elem.templates_children.forEach((child) => child.not_use ? null : elem.children.push(child));

    if (elem.user_pages_children.length > 0) elem.is_ghost = false;

    if (elem.children.length < 1) elem.not_use = true;
  });
}

function getFinalFilteredPages(pagesDict) {
  return Object.values(pagesDict).filter((elem) => !elem.not_use);
}

function sortFinalPages(pagesList) {
  function pageSorter(aPage, bPage) {
    const aTemplate = aPage.is_template || (aPage.parent_page != null && aPage.parent_page.is_template);
    const bTemplate = bPage.is_template || (bPage.parent_page != null && bPage.parent_page.is_template);

    if (aTemplate && !bTemplate) return -1;
    if (!aTemplate && bTemplate) return 1;

    if (aPage.internal_page && !bPage.internal_page) return 1;
    if (!aPage.internal_page && bPage.internal_page) return -1;

    const aPrior = aPage.priority ? aPage.priority : 0;
    const bPrior = bPage.priority ? bPage.priority : 0;

    if (aPrior < bPrior) return -1;
    if (aPrior > bPrior) return 1;

    if (String(aPage.name).toLowerCase() < String(bPage.name).toLowerCase()) return -1;
    if (String(aPage.name).toLowerCase() > String(bPage.name).toLowerCase()) return 1;
    return 0;
  }

  pagesList.sort(pageSorter);

  pagesList.forEach((elem) => elem.children.sort(pageSorter));
}

function mergePages(userPages, templatePages, localPages) {
  // console.log('MERGER', templatePages, userPages, localPages);

  prepareTemplatePages(templatePages);
  const plainPagesDict = getPlainPagesDict(userPages, templatePages, localPages);
  fillPageRelations(plainPagesDict);
  const ans = getFinalFilteredPages(plainPagesDict);
  sortFinalPages(ans);
  return ans;
}

function rebuildSiteStruct() {
  const localPages = pagesInject(store.state.user_obj);

  store.state.web_site_struct = mergePages(
      store.state.raw_pages_struct == null ? [] : store.state.raw_pages_struct,
      store.state.page_templates_struct == null ? [] : store.state.page_templates_struct,
      localPages,
  );

  store.state.fast_panel_struct = store.state.web_site_struct.filter((elem) => elem.in_fast_panel);

  // console.log('store.state.web_site_struct', store.state.web_site_struct);
}

export default store;
